import React from "react"
import Header from '../components/header';
import NameFace from '../components/nameAndFace';
import experiences from '../data.json';
import ExperienceList from '../components/ExperienceList';
import FooterPage from '../components/Footer';
import ProfilePage from '../components/ProfilePage';

export default () => {
    return (
        <div>
            <Header />
            <NameFace />
            <ProfilePage />
            <ExperienceList experiences={experiences}/>
            <FooterPage />
        </div>
    );
};
