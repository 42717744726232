import React from 'react';
import nameFaceStyles from './nameAndFace.module.css';

export default () => {
    return (
        <div className={nameFaceStyles.body}>
            <div>
                <h1>Hey!</h1>
                <h1>I'm Dan Park</h1>
            </div>
        </div>
    );
};