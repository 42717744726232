import React from 'react';
import ExperienceItem from './ExperienceItem';
import ExperienceListStyles from './ExperienceList.module.css';

export default (props) => {

    var headerStyle = {
        color: 'black',
        fontSize: '54px',
        marginTop: `15px`,
        marginBottom: `50px`,
        marginLeft: `100px`,
        textAlign: `center`,
    };
    return (
        <div className={ExperienceListStyles.container}>
            <div className={ExperienceListStyles.experiencesContainer}>
                <h1 style={headerStyle}>Experiences</h1>
                <div>
                    {props.experiences.map((experience, index) => {
                                return <ExperienceItem key={index} experience={experience}/>;
                    })}
                </div>
            </div>
        </div>
    );
};