import React from 'react';
import headerStyles from './header.module.css';
import { Link } from 'gatsby';

export default () => {
    return (
            <div className={headerStyles.header}>
                <Link 
                    to="/"
                    className={headerStyles.link}
                    activeClassName={{ color: "black" }}
                >
                    Home
                </Link>
                <Link 
                    to="/blog"
                    className={headerStyles.link}
                >
                    Blog
                </Link>
            </div>
    );
}