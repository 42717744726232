import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import React from 'react';
import ExperienceItemStyles from './ExperienceItem.module.css';
export default (props) => {
    const data = useStaticQuery(graphql`
        query {
            allFile(filter: {absolutePath: {regex: "/static/"}}) {
            edges {
                node {
                childImageSharp {
                    fixed(width: 150, height: 150) {
                    ...GatsbyImageSharpFixed
                    }
                }
                }
            }
            }
        }
    `)
    
    //console.log('gatsbyURL', JSON.stringify(data.allFile.edges[1].node.childImageSharp.fixed.src.split("/")[data.allFile.edges[1].node.childImageSharp.fixed.src.split("/").length-1]));
    // Find logo
    let logoURL = '';
    data.allFile.edges.forEach((edge) => {
        if (edge.node!==null && edge.node.childImageSharp!== null) {
            // find src
            const src = edge.node.childImageSharp.fixed.src.split("/")[edge.node.childImageSharp.fixed.src.split("/").length-1] 
            if (src === props.experience.logo) {
                logoURL = edge.node.childImageSharp.fixed;
            }
        }
    });

    return (
        <div className={ExperienceItemStyles.container}>
            <div className={ExperienceItemStyles.logo}>
                {/* <img src={props.experience.logo} alt="Company logo unavailable"></img> */}
                <Img fixed={logoURL} objectFit="cover" alt="No Company Logo"/>
            </div>
            <div>
                <h2>{props.experience.company}</h2>
                <h3>{props.experience.title}, {props.experience.yearsWorked}</h3>
                <h3>{props.experience.location}</h3>
                <ul className={ExperienceItemStyles.list_container}>
                    {props.experience.description.map((descript, index) => {
                        return <li key={index} className={ExperienceItemStyles.list}>{descript}</li>;
                    })}
                </ul>
            </div>
        </div>
    );
};