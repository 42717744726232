import React from 'react';
import instagram from '../../static/insta_logo.svg';
import linkedIn from '../../static/linkedin_logo.svg';
import venmo from '../../static/venmo_logo.png'
import FooterStyles from './Footer.module.css';

export default () => {
    return (
        <div className={FooterStyles.container}>
            <div className={FooterStyles}>
                <h1>Contact me</h1>
                <div className={FooterStyles.description}>
                    <p>Let's connect! DM me on instagram or Linkedin.</p>
                    <p>Got any interesting project or idea you want to work on with someone? Feel free to share! Always looking for something fun to do.</p>
                </div>
                <a href="//linkedin.com/in/daniel-j-park">
                    <img src={linkedIn} className={FooterStyles.logo}></img>
                </a>
                <a href="//instagram.com/dpark__93_/">
                    <img src={instagram} className={FooterStyles.logo}></img>
                </a>
                <a href="//venmo.com/Daniel-Park-36">
                    <img src={venmo} className={FooterStyles.logo}></img>
                </a>
            </div>
        </div>
    );
}