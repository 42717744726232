import React from 'react';
import profilePhoto from '../../static/profile_photo.png';
import ProfilePageStyles from './ProfilePage.module.css';

export default (props) => {
    
    return (
        <div className={ProfilePageStyles.container}>
            <div className={ProfilePageStyles.profileContainer}>
                <h1 className={ProfilePageStyles.profileHeader}>Profile</h1>
                <div className={ProfilePageStyles.profileHeaderContainer}>
                    <img className={ProfilePageStyles.coverPhoto} src={profilePhoto}></img>
                    <div className={ProfilePageStyles.me}>
                        <h4>Name:</h4>
                        <p>Daniel Jin Park</p>
                        <h4>Age:</h4>
                        <p>26</p>
                        <h4>Location:</h4>
                        <p>New York, NY</p>
                    </div>
                </div>
            
                <div className={ProfilePageStyles.aboutMe}>
                    <h2>About me</h2>
                    <div className={ProfilePageStyles.aboutMeDescription}>
                    <p> 
                    Hi there, I'm Dan and I currently work as a senior developer at Pariveda Solutions and have strived to excel in web application development.
                    I graduated from Bucknell University with a B.S. in Electrical Engineering and since then have developed a passion for software development.
                    I enjoy solving complex business problems through a technology lense and have worked with various different industries (ecommerce, p2p payments, aerospace, telecommunications). 
                    Whether it's a problem with scaling a commercial application or creating an greenfield app, I say bring it on!
                    In my spare time, I like to work on various different side projects and have been earger to learn more of the unknown. I also love to travel during my free time and play tennis.

                    </p>
                    </div>
                </div>
            </div>
        </div>
    );
}